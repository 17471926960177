import { DefaultTheme } from "styled-components"

export const breakpoints = {
  small: "40em", // 640px
  medium: "62.5em", // 1000px
  large: "65em", // 1024px
  xlarge: "90em",
}

export const theme: DefaultTheme = {
  breakpoints: Object.values(breakpoints),
  fonts: {
    sansSerif: `'Lato', sans-serif`,
    serif: `'Frank Ruhl Libre', serif`,
  },
  zIndex: {
    header: 9,
    overlay: 3,
    cookieNotice: 8,
    sidebar: 18,
    overlaySidebar: 10,
    notifications: 30,
  },
  colors: {
    softGrey: "#E5E5E5",
    card: {
      default: "#FAFAFA",
      active: "#F0F0F0",
    },
    text: {
      dark: "#282626",
      light: "#808080",
      lightpurple: "#aea4b8",
    },
    orange: "#EE722E",
    purple: "#341C4E",
    purpleLight: "#4D3667",
    beige: "#ECE7E3",
    red: {
      border: "#BF2525",
      icon: "#C04747",
      text: "#BF2525",
      bg: "#F2D3D3",
      primary: "#BF2525",
    },
    green: {
      border: "#7EC17C",
      icon: "#4FBD82",
      text: "#2A5529",
      bg: "#EFFAF0",
      primary: "#25BF3E",
    },
  },
}
