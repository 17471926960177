export type NotificationsContent = {
  addedToCart: string
  removedFromCart: string
  isEdited: string
  giftcardAdded: string
  paymentMethodRefused: string
  convertingToOrderFailed: string
}

type Notifications = {
  [locale: string]: NotificationsContent
}

export const notifications: Notifications = {
  nl: {
    addedToCart: "is toegevoegd",
    removedFromCart: "is verwijderd",
    isEdited: "is aangepast",
    giftcardAdded: "giftcard is toegevoegd",
    paymentMethodRefused:
      "De gekozen betaalwijze is geweigerd. Controleer aub uw gegevens en probeer opnieuw.",
    convertingToOrderFailed: "Het omzetten naar een order is mislukt",
  },
  en: {
    addedToCart: "added to cart",
    removedFromCart: "is removed",
    isEdited: "is changed",
    giftcardAdded: "giftcard is toegevoegd",
    paymentMethodRefused:
      "Your payment method is refused, please check your details and try again.",
    convertingToOrderFailed: "Converting to order failed",
  },
}
