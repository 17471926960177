import styled, { css } from "styled-components"
import * as p from "polished"
import { breakpoints } from "@config/theme"

export const TitleMStyle = css`
  font-weight: normal;
  font-size: ${p.rem(22)};
  line-height: 1.2;
  font-family: ${(p) => p.theme.fonts.serif};
  @media screen and (min-width: ${breakpoints.small}) {
    font-size: ${p.rem(22)};
  }
  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: ${p.rem(24)};
  }
`

export const TitleM = styled.h3`
  ${TitleMStyle};
`
