import styled, { css } from "styled-components"
import { TitleLAltStyle } from "./TitleLAlt"

export const CityTitleStyle = css`
  ${TitleLAltStyle};
  color: ${(p) => p.theme.colors.text.light};
  position: relative;
  display: inline-block;
  margin: 2rem 0;

  &:after {
    position: absolute;
    bottom: 40%;
    right: -40px;
    width: 30px;
    height: 1px;
    display: block;
    content: "";
    background: ${(p) => p.theme.colors.text.light};
  }
`

export const CityTitle = styled.h2`
  ${CityTitleStyle};
`
