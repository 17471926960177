import styled, { css } from "styled-components"
import * as p from "polished"

export const LinkStyle = css`
  font-size: ${p.rem(14)};
  font-weight: normal;
  line-height: ${p.rem(17)};
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;

  &:after {
    display: block;
    content: "";
    height: 1px;
    position: absolute;
    width: 0%;
    transition: width 0.2s ease-in-out;
    background: ${(p) => p.theme.colors.purple};
    bottom: -2px;
    left: 0;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
  &:active {
    font-weight: bold;
  }
`

export const Link = styled.a`
  ${LinkStyle};
`
