import React from "react" // eslint-disable-line
import IAddToBag from "./files-new/add-to-bag.svg"
import IArrowLeft from "./files-new/arrow-left.svg"
import IArrowRight from "./files-new/arrow-right.svg"
import IAuthority from "./files-new/authority.svg"
import ICheck from "./files-new/check.svg"
import ICheckSmall from "./files-new/check-small.svg"
import ICheckoutCheckmark from "./files-new/checkout-checkmark.svg"
import IChevronSmall from "./files-new/chevron-small.svg"
import IChevronRight from "./files-new/chevron-right.svg"
import IChevronLeft from "./files-new/chevron-left.svg"
import ICross from "./files-new/cross.svg"
import IDropdownSmall from "./files-new/dropdown-small.svg"
import IErrorSmall from "./files-new/error-small.svg"
import IFilter from "./files-new/filter.svg"
import IFreeReturns from "./files-new/free-returns.svg"
import IFreeShipping from "./files-new/free-shipping.svg"
import IInfo from "./files-new/info.svg"
import IMenu from "./files-new/menu.svg"
import IMinus from "./files-new/minus.svg"
import IPlus from "./files-new/plus.svg"
import ILogo from "./files-new/logo.svg"
import IQuestionmark from "./files-new/question-mark.svg"
import ILock from "./files-new/lock.svg"
import IUKFlag from "./files-new/uk-flag.svg"
import INLFlag from "./files-new/nl-flag.svg"
import ILoader from "./files-new/loader.svg"
import ISmall from "./files-new/small.svg"
import IMedium from "./files-new/medium.svg"
import ILarge from "./files-new/large.svg"
import IClassic from "./files-new/classic.svg"
import IOval from "./files-new/oval.svg"
import IHippieChic from "./files-new/hippiechic.svg"
import ITwin from "./files-new/twin.svg"
import ISearch from "./files-new/search.svg"
import ISizes from "./files-new/sizes.svg"
import IStyles from "./files-new/styles.svg"
import IOnePiece from "./files-new/one-piece.svg"
import Iinstagram from "./files-new/instagram.svg"
import IConfigure from "./files-new/configure.svg"
import IFacebook from "./files-new/facebook.svg"
import IEmail from "./files-new/email.svg"
import IGift from "./files-new/gift.svg"

export const AddToBag = IAddToBag
export const ArrowLeft = IArrowLeft
export const ArrowRight = IArrowRight
export const Authority = IAuthority
export const Check = ICheck
export const CheckSmall = ICheckSmall
export const CheckoutCheckmark = ICheckoutCheckmark
export const ChevronSmall = IChevronSmall
export const ChevronRight = IChevronRight
export const ChevronLeft = IChevronLeft
export const Cross = ICross
export const DropdownSmall = IDropdownSmall
export const ErrorSmall = IErrorSmall
export const Filter = IFilter
export const FreeReturns = IFreeReturns
export const FreeShipping = IFreeShipping
export const Info = IInfo
export const Menu = IMenu
export const Minus = IMinus
export const Plus = IPlus
export const Logo = ILogo
export const Questionmark = IQuestionmark
export const Lock = ILock
export const UKFlag = IUKFlag
export const NLFlag = INLFlag
export const Loader = ILoader
export const Small = ISmall
export const Medium = IMedium
export const Large = ILarge
export const Classic = IClassic
export const Oval = IOval
export const HippieChic = IHippieChic
export const Twin = ITwin
export const Search = ISearch
export const Styles = IStyles
export const Sizes = ISizes
export const OnePiece = IOnePiece
export const Instagram = Iinstagram
export const Configure = IConfigure
export const Facebook = IFacebook
export const Email = IEmail
export const Gift = IGift
