import { warranty, WarrantyContent } from "./warranty"
import { CheckoutContent, checkout } from "./checkout"
import { configurator, ConfiguratorContent } from "./configurator"
import { collectionDetail, CollectionDetailContent } from "./collectionDetail"
import { PropertiesContent, properties } from "./properties"
import { cart, CartContent } from "./cart"
import { retailers, RetailersContent } from "./retailers"
import { FooterContent, footer } from "./footer"
import { home, HomeContent } from "./home"
import { navigation, NavigationContent } from "./navigation"
import { GlobalContent, global } from "./global"
import { lookDetail, LookDetailContent } from "./lookDetail"
import { productDetail, ProductDetailContent } from "./productDetail"
import { notifications, NotificationsContent } from "./notifications"
import { returnForm, ReturnFormContent } from "./returnForm"
import { newsletter, NewsletterContent } from "./newsletter"
import { cookies, CookiesContent } from "./cookies"
import { search, SearchContent } from "./search"
import { vote, VoteContent } from "./vote"

export interface MessagesContent {
  global: GlobalContent
  navigation: NavigationContent
  footer: FooterContent
  notifications: NotificationsContent
  home: HomeContent
  lookDetail: LookDetailContent
  productDetail: ProductDetailContent
  collectionDetail: CollectionDetailContent
  retailers: RetailersContent
  cart: CartContent
  properties: PropertiesContent
  configurator: ConfiguratorContent
  returnForm: ReturnFormContent
  checkout: CheckoutContent
  warranty: WarrantyContent
  newsletter: NewsletterContent
  cookies: CookiesContent
  search: SearchContent
  vote: VoteContent
}
export interface Messages {
  [lang: string]: MessagesContent
}

export const messages: Messages = {
  nl: {
    global: global.nl,
    navigation: navigation.nl,
    footer: footer.nl,
    notifications: notifications.nl,
    properties: properties.nl,
    home: home.nl,
    lookDetail: lookDetail.nl,
    productDetail: productDetail.nl,
    collectionDetail: collectionDetail.nl,
    retailers: retailers.nl,
    cart: cart.nl,
    configurator: configurator.nl,
    returnForm: returnForm.nl,
    checkout: checkout.nl,
    warranty: warranty.nl,
    newsletter: newsletter.nl,
    cookies: cookies.nl,
    search: search.nl,
    vote: vote.nl,
  },
  en: {
    global: global.en,
    navigation: navigation.en,
    footer: footer.en,
    notifications: notifications.en,
    properties: properties.en,
    home: home.en,
    lookDetail: lookDetail.en,
    productDetail: productDetail.en,
    collectionDetail: collectionDetail.en,
    retailers: retailers.en,
    cart: cart.en,
    configurator: configurator.en,
    returnForm: returnForm.en,
    checkout: checkout.en,
    warranty: warranty.en,
    newsletter: newsletter.en,
    cookies: cookies.en,
    search: search.en,
    vote: vote.en,
  },
}
