import styled, { css } from "styled-components"
import * as p from "polished"
import { SpaceProps, space } from "styled-system"

type Props = SpaceProps

export const LabelSStyle = css`
  font-size: ${p.rem(12)};
  font-weight: normal;
  line-height: ${p.rem(18)};
`

export const LabelS = styled.p<Props>`
  ${LabelSStyle};
  ${space};
`
