export type FooterContent = {
  navigation: string
  followUs: string
  callUs: string
  mailUs: string
}

type Footer = {
  [locale: string]: FooterContent
}

export const footer: Footer = {
  nl: {
    navigation: "Navigatie",
    followUs: "Volg ons",
    callUs: "Bel ons",
    mailUs: "Mail ons",
  },
  en: {
    navigation: "Navigation",
    followUs: "Follow us",
    callUs: "Call us",
    mailUs: "Mail us",
  },
}
