import styled, { css } from "styled-components"

export const AnchorStyle = css`
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  color: black;
  position: relative;
  &:after {
    display: block;
    content: "";
    height: 1px;
    position: absolute;
    width: 0%;
    transition: width 0.2s ease-in-out;
    background: ${(p) => p.theme.colors.purple};
    bottom: -2px;
    left: 0;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
`

export const Anchor = styled.a`
  ${AnchorStyle};
`
