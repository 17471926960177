import styled, { css } from "styled-components"
import * as p from "polished"
import { SpaceProps, space } from "styled-system"

export const TitleLAltStyle = css`
  font-size: ${p.rem(28)};
  font-weight: normal;
  line-height: ${p.rem(38)};
  font-family: ${(p) => p.theme.fonts.serif};
`

export const TitleLAlt = styled.h2<SpaceProps>`
  ${TitleLAltStyle};
  ${space};
`

TitleLAlt.defaultProps = {
  margin: "0 0 1rem",
}
