export type ConfiguratorContent = {
  ring: string
  earring: string
  base: string
  stone: string
  frame: string
  tryAgain: string
  configureYourChoices: string
  viewCollectionAndConfigure: string
  configureYourChoicesDescription: string
  loadError: string
}

type Configurator = {
  [locale: string]: ConfiguratorContent
}

export const configurator: Configurator = {
  nl: {
    ring: "Ring",
    earring: "Oorbel",
    base: "Base",
    stone: "Steen",
    frame: "Frame",
    tryAgain: "Probeer opnieuw",
    configureYourChoices: "Configure your choices",
    viewCollectionAndConfigure: "View collection & configure",
    configureYourChoicesDescription:
      "To configure your jewellry, please visit our collection and pick a base collection and style.",
    loadError: "Er ging iets fout tijdens het laden, probeer het opnieuw",
  },
  en: {
    ring: "Ring",
    earring: "Earring",
    base: "Base",
    stone: "Stone",
    frame: "Frame",
    tryAgain: "Try again",
    configureYourChoices: "Configure your choices",
    configureYourChoicesDescription:
      "To configure your jewellry, please visit our collection and pick a base collection and style.",
    viewCollectionAndConfigure: "View collection & configure",
    loadError: "Something went wrong loading the page, please try again",
  },
}
