export type SearchContent = {
  all: string
  small: string
  medium: string
  large: string
  stone: string
  frame: string
  base: string
  bracelet: string
  earring: string
  necklace: string
  ring: string
  hippiechic: string
  classic: string
  oval: string
  twin: string
  allSizes: string
  allStyles: string
}

type Configurator = {
  [locale: string]: SearchContent
}
// size: "small" | "medium" | "large"
// product_group: "stone" | "frame" | "base" | "bracelet" | "earring" | "necklace" | "ring"
// style: "hippiechic" | "classic" | "oval" | "twin"
export const search: Configurator = {
  nl: {
    all: "Alles",
    small: "Small",
    medium: "Medium",
    large: "Large",
    stone: "Stenen",
    frame: "Frames",
    base: "Bases",
    bracelet: "Armbanden",
    earring: "Oorbellen",
    necklace: "Colliers",
    ring: "Ringen",
    hippiechic: "Hippie Chic",
    classic: "Classic",
    oval: "Oval",
    twin: "Twin",
    allSizes: "Alle maten",
    allStyles: "Alle stijlen",
  },
  en: {
    all: "All",
    small: "Small",
    medium: "Medium",
    large: "Large",
    stone: "Stones",
    frame: "Frames",
    base: "Bases",
    bracelet: "Bracelets",
    earring: "Earrings",
    necklace: "Necklaces",
    ring: "Rings",
    hippiechic: "Hippie Chic",
    classic: "Classic",
    oval: "Oval",
    twin: "Twin",
    allSizes: "All sizes",
    allStyles: "All styles",
  },
}
