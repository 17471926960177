export type GlobalContent = {
  view: string
  showMore: string
  configure: string
  update: string
  addToBag: string
  oops: string
  required: string
  invalid: string
  phoneNumber: string
  bankAccount: string
  taxNumber: string
  error404: string
  errorXxx: string
  language: string
  english: string
  dutch: string
  close: string
  seoDescription: string
  readMore: string
  readLess: string
  tryNow: string
  checkInstagramFilter: string
  aboutProduct: string
  giftWithPurchaseTitle: string
  applicableGiftsWithPurchase1: string
  applicableGiftsWithPurchase2: string
  applicableGiftsWithPurchase3: string
}

type Global = {
  [locale: string]: GlobalContent
}

export const global: Global = {
  nl: {
    view: "Bekijk",
    showMore: "Toon meer",
    configure: "Stel samen",
    update: "Opslaan",
    addToBag: "In winkelmand",
    oops: "Foutje.",
    required: " is verplicht",
    invalid: " is niet geldig",
    phoneNumber: "+(31)20-6225333",
    bankAccount: "IBAN",
    taxNumber: "BTW nummer",
    error404: "Deze pagina kon niet gevonden worden.",
    errorXxx:
      "Er ging iets fout, probeer het nog eens of neem contact op met de site beheerder.",
    language: "Taal",
    english: "Engels",
    dutch: "Nederlands",
    close: "Sluiten",
    seoDescription:
      "Interchangeable jewellery - Mix and Match uw persoonlijke juwelengarderobe online of in de winkel: ✓ Oorbellen ✓ Ringen ✓ Colliers ✓ Armbanden ✓ Kleurstenen",
    readMore: "Lees meer",
    readLess: "Lees minder",
    tryNow: "Probeer nu",
    checkInstagramFilter:
      "Bekijk hoe de oorbellen jouw staan met ons Instagram filter.",
    aboutProduct: "Over dit product",
    giftWithPurchaseTitle: "Ontvang een cadeau bij uw aankoop",
    applicableGiftsWithPurchase1:
      "U ontvangt een gratis Choices by DL beauty pouch als cadeau.",
    applicableGiftsWithPurchase2:
      "U ontvangt een gratis Choices by DL hammamdoek als cadeau.",
    applicableGiftsWithPurchase3:
      "U ontvangt een gratis Choices by DL kussen als cadeau.",
  },
  en: {
    view: "View",
    showMore: "Show more",
    configure: "Configure",
    update: "Update",
    addToBag: "Add to bag",
    oops: "Oops.",
    required: " is required",
    invalid: " is invalid",
    phoneNumber: "+(31)20-6225333",
    bankAccount: "Bank account",
    taxNumber: "Tax number",
    error404: "This page could not be found.",
    errorXxx:
      "Something went wrong, please try again or contact the site administrator.",
    language: "Language",
    english: "English",
    dutch: "Dutch",
    close: "Close",
    seoDescription:
      "Interchangeable jewellery - Mix and Match your personal jewellery wardrobe online or in the shop: ✓ Earrings ✓ Rings ✓ Necklaces ✓ Bracelets ✓ Color stones",
    readMore: "Read more",
    readLess: "Read less",
    tryNow: "Try now",
    checkInstagramFilter:
      "See how the earrings look on you with our Instagram filter.",
    aboutProduct: "About this product",
    giftWithPurchaseTitle: "Receive a gift with your purchase!",
    applicableGiftsWithPurchase1:
      "You will receive a Choices by DL beauty pouch as a gift.",
    applicableGiftsWithPurchase2:
      "You will receive a Choices by DL hammam towel as a gift.",
    applicableGiftsWithPurchase3:
      "You will receive a Choices by DL pillow as a gift.",
  },
}
