import styled from "styled-components"
import { Box } from "./Box"
import { rem } from "polished"
export { Box } from "./Box"

// @ts-ignore
export const Container = styled(Box)``

Container.defaultProps = {
  maxWidth: rem(1200),
  margin: "0 auto",
  width: "90%",
}
