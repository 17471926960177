import * as Cookies from "js-cookie"
import { useRouter } from "next/router"
import React, { useContext, useState } from "react"
import { UrlObject } from "url"
import { MessagesContent, messages } from "./messages"
export type Locale = "nl" | "en"

interface IntlState {
  locale: string
  messages: MessagesContent
}

interface IntlContext extends IntlState {
  setLocale: (locale: string) => void
  intlPush: (
    url: UrlObject | string,
    as?: UrlObject | string,
    options?: {}
  ) => Promise<boolean>
}

const Context = React.createContext<IntlContext>({
  messages: messages.nl,
  locale: "nl",
  setLocale: (locale: any) => null,
  intlPush: (url: UrlObject | string, as?: UrlObject | string, options?: {}) =>
    new Promise((r) => r(true)),
})

interface Props {
  initialLocale: string
}

export const IntlProvider: React.FC<Props> = ({ initialLocale, children }) => {
  const [locale, setLocaleState] = useState<string>(initialLocale)
  const router = useRouter()
  const localeMessages = messages[locale]

  const handleSetLocale = (newLocale: string) => {
    if (locale !== newLocale) {
      setLocaleState(newLocale)

      let newUrl: string | null = null
      if (typeof window !== "undefined") {
        if (newLocale === "nl") {
          const alternate: HTMLLinkElement | null = document.querySelector(
            'link[hreflang="nl"]'
          )
          newUrl = alternate ? alternate.href : window.location.origin + "/nl"
        } else {
          const alternate: HTMLLinkElement | null = document.querySelector(
            'link[hreflang="en"]'
          )
          newUrl = alternate ? alternate.href : window.location.origin + "/en"
        }
      }

      Cookies.set("lang", newLocale)
      if (newUrl) {
        window.location.href = newUrl
      }
    }
  }

  // Small wrapper around router.push that prefixes '/en' if locale is english
  const intlPush = (
    url: UrlObject | string,
    as?: UrlObject | string,
    options?: {}
  ) => {
    const prefix = `/${locale}`
    let prefixedUrl = typeof url === "string" ? `/[lang]${url}` : url
    let prefixedAs = typeof as === "string" ? `${prefix}${as}` : as
    return router.push(prefixedUrl, prefixedAs, options)
  }

  return (
    <Context.Provider
      value={{
        locale,
        messages: localeMessages,
        setLocale: handleSetLocale,
        intlPush,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useIntl = (): IntlContext => {
  const ctx = useContext(Context)
  return ctx
}
