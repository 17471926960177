export type HomeContent = {
  viewRetailers: string
  moreLooks: string
}

type Home = {
  [locale: string]: HomeContent
}

export const home: Home = {
  nl: {
    viewRetailers: "Bekijk retailers",
    moreLooks: "Meer looks",
  },
  en: {
    viewRetailers: "View retailers",
    moreLooks: "More looks",
  },
}
