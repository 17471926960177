import styled, { css } from "styled-components"
import * as p from "polished"
import { color, ColorProps } from "styled-system"

export const ParagraphSStyle = css<ColorProps>`
  font-size: ${p.rem(14)};
  font-weight: normal;
  line-height: ${p.rem(21)};
  ${color};
`

export const ParagraphS = styled.p`
  ${ParagraphSStyle};
`
