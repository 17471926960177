export type ProductDetailContent = {
  patentedSystem: string
  freeShippingReturns: string
  giftcardValue: string
  min20euro: string
}

type ProductDetail = {
  [locale: string]: ProductDetailContent
}

export const productDetail: ProductDetail = {
  nl: {
    freeShippingReturns: "Free shipping and returns",
    patentedSystem: "Patented bayonet locking-system",
    giftcardValue: "Waarde van de giftcard",
    min20euro: "Minimaal 20 euro, maximaal 1000 euro",
  },
  en: {
    freeShippingReturns: "Free shipping and returns",
    patentedSystem: "Patented bayonet locking-system",
    giftcardValue: "Giftcard value",
    min20euro: "Minimum 20 euro, maximum 1000 euro",
  },
}
