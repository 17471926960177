export type CollectionDetailContent = {
  moreFilters: string
}

type CollectionDetail = {
  [locale: string]: CollectionDetailContent
}

export const collectionDetail: CollectionDetail = {
  nl: {
    moreFilters: "Meer filters",
  },
  en: {
    moreFilters: "More filters",
  },
}
