import styled, { css } from "styled-components"
import { breakpoints } from "@config/theme"
import * as p from "polished"
import { space, SpaceProps } from "styled-system"

export const TitleXLStyle = css`
  ${space};
  font-weight: normal;
  line-height: 1.3;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fonts.serif};
  font-size: ${p.rem(20)};
  margin-top: 0;

  @media screen and (min-width: ${breakpoints.small}) {
    font-size: ${p.rem(42)};
  }
`

export const TitleXL = styled.h1<SpaceProps>`
  ${TitleXLStyle};
`
TitleXL.defaultProps = {
  mb: 3,
}
