export type CheckoutContent = {
  continueShopping: string
  previousStep: string
  continue: string
  confirmPay: string
  free: string
  selectPaymentOption: string
  tax: string
  anyQuestions: string
  processing: string
  details: {
    yourOrder: string
    yourDetails: string
    orderType: string
    companyName: string
    name: string
    firstName: string
    lastName: string
    phone: string
    phoneNumber: string
    email: string
    emailAddress: string
    postalCode: string
    houseNumber: string
    no: string
    suffix: string
    invoiceAddress: string
    invoiceAddressSame: string
    optional: string
    private: string
    international: string
    b2b: string
    city: string
    street: string
    edit: string
    state: string
    country: string
    select: string
    signupForNewsletterTitle: string
    signupForNewsletter: string
  }
  giftcards: {
    redeemGiftCard: string
    giftcardNumber: string
    securityCode: string
    redeem: string
    cancel: string
    giftcardAdded: string
    noMoreGiftcardsCanBeAdded: string
    giftcardHasBeenAdded: string
    giftcardHasBeenRemoved: string
    giftcardNoAmountLeft: string
  }
  voucher: {
    redeemVoucher: string
    voucherCode: string
    redeem: string
    cancel: string
    voucherAdded: string
    noMoreVouchersCanBeAdded: string
    voucherHasBeenAdded: string
    voucherHasBeenRemoved: string
    voucherIsInvalid: string
  }
  payment: {
    processing: string
    pleaseWait: string
    paymentError: string
    pleaseContactUs: string
    contactUs: string
  }
  summary: {
    summary: string
    subtotal: string
    deliveryAddress: string
    contactInformation: string
    editDetails: string
    paymentMethod: string
    editPaymentDetails: string
    agreeWithOur: string
    termsConditions: string
    privacyStatement: string
    continueToAgreeFirst: string
    continueToAgreeSecond: string
    showCountMore: string
    ringsize: string
    ringsizeToBeDetermined: string
    orderNumber: string
  }
  thanks: {
    thanks: string
    processing: string
    returnHome: string
  }
  paymentError: {
    title: string
    text: string
    button: string
  }
}

type Checkout = {
  [locale: string]: CheckoutContent
}

export const checkout: Checkout = {
  nl: {
    continueShopping: "shoppen",
    previousStep: "Vorige stap",
    continue: "Doorgaan",
    confirmPay: "Bevestig & betaal",
    free: "Gratis",
    selectPaymentOption: "Selecteer een betaalmethode",
    tax: "BTW",
    anyQuestions: "Heeft u vragen? Bel:",
    processing: "Verwerken...",
    details: {
      yourOrder: "Uw bestelling",
      yourDetails: "Jouw gegevens",
      orderType: "Order type",
      companyName: "Bedrijfsnaam",
      name: "Naam",
      firstName: "Voornaam",
      lastName: "Achternaam",
      phone: "Telefoon",
      phoneNumber: "Telefoonnummer",
      email: "Email",
      emailAddress: "Email adres",
      postalCode: "Postcode",
      houseNumber: "Huisnummer",
      no: "Nr.",
      suffix: "Toev.",
      invoiceAddress: "Factuuradres",
      invoiceAddressSame: "Factuuradres is hetzelfde als bezorgadres",
      optional: "optioneel",
      private: "Privé",
      international: "Internationaal",
      b2b: "Bedrijf",
      city: "Plaats",
      street: "Straatnaam",
      edit: "aanpassen",
      state: "Provincie",
      country: "Land",
      select: "Selecteer",
      signupForNewsletterTitle: "Nieuwsbrief",
      signupForNewsletter: "Inschrijven voor onze nieuwsbrief",
    },
    giftcards: {
      redeemGiftCard: "Giftcard inwisselen",
      giftcardNumber: "Giftcardnummer",
      securityCode: "Veiligheidscode",
      redeem: "Ophalen",
      cancel: "annuleren",
      giftcardAdded: "Giftcard toegevoegd",
      noMoreGiftcardsCanBeAdded:
        "Er kunnen geen giftcards meer worden toegevoegd",
      giftcardHasBeenAdded: "De giftcard is toegevoegd aan uw bestelling.",
      giftcardHasBeenRemoved: "De giftcard is verwijderd uit uw bestelling.",
      giftcardNoAmountLeft: "De giftcard heeft geen tegoed meer",
    },
    voucher: {
      redeemVoucher: "Vouchercode invoeren",
      voucherCode: "Vouchercode",
      redeem: "Ophalen",
      cancel: "annuleren",
      voucherAdded: "Voucher toegevoegd",
      noMoreVouchersCanBeAdded:
        "Er kunnen geen vouchers meer worden toegevoegd",
      voucherHasBeenAdded: "De voucher is toegevoegd aan uw bestelling.",
      voucherHasBeenRemoved: "De voucher is verwijderd uit uw bestelling.",
      voucherIsInvalid: "De vouchercode is niet geldig",
    },
    payment: {
      processing: "Verwerken",
      pleaseWait: "Een moment geduld a.u.b..",
      paymentError: "Betalingsfout",
      pleaseContactUs:
        "Er is een fout opgetreden bij het uitvoeren van de betaling.",
      contactUs: "Neem contact op",
    },
    summary: {
      summary: "Overzicht",
      subtotal: "Subtotaal",
      deliveryAddress: "Bezorgadres",
      contactInformation: "Contactinformatie",
      editDetails: "Aanpassen",
      paymentMethod: "Betaalwijze",
      editPaymentDetails: "Betaalwijze aanpassen",
      agreeWithOur: "Ik ga akkoord met",
      termsConditions: "algemene voorwaarden",
      privacyStatement: "privacy statement",
      continueToAgreeFirst:
        "Door verder te gaan naar betalen gaat u akkoord met onze",
      continueToAgreeSecond: "en ons",
      showCountMore: "Toon |%COUNT%| meer",
      ringsize: "Ringmaat",
      ringsizeToBeDetermined: "Ringmaat nog te bepalen",
      orderNumber: "Ordernummer",
    },
    thanks: {
      thanks: "Bedankt! Uw order is geplaatst",
      processing:
        "We gaan aan de slag met uw order. We houden u op de hoogte op",
      returnHome: "Naar de homepage",
    },
    paymentError: {
      title: "Betalingsfout",
      text: "Er is een fout opgetreden bij het verwerken van uw betaling. Probeer het opnieuw of neem contact met ons op.",
      button: "Terug naar bestellen",
    },
  },
  en: {
    continueShopping: "Continue shopping",
    previousStep: "Previous step",
    continue: "Continue",
    confirmPay: "Confirm & pay",
    free: "Free",
    selectPaymentOption: "Select a payment option",
    tax: "Tax",
    anyQuestions: "Any questions? Call:",
    processing: "Processing...",
    details: {
      yourOrder: "Your order",
      yourDetails: "Your details",
      orderType: "Order type",
      companyName: "Company name",
      name: "Name",
      firstName: "First name",
      lastName: "Last name",
      phone: "Phone",
      phoneNumber: "Phone number",
      email: "Email",
      emailAddress: "Email address",
      postalCode: "Postal code",
      houseNumber: "House number",
      no: "No.",
      suffix: "Suffix",
      invoiceAddress: "Invoice address",
      invoiceAddressSame:
        "Invoice address is the same as the delivery adress above",
      optional: "optional",
      private: "Private",
      international: "International",
      b2b: "B2B",
      city: "City",
      street: "Street",
      edit: "edit",
      state: "State",
      country: "Country",
      select: "Select",
      signupForNewsletterTitle: "Newsletter",
      signupForNewsletter: "Signup for our newsletter",
    },
    summary: {
      summary: "Summary",
      subtotal: "Subtotal",
      deliveryAddress: "Delivery address",
      contactInformation: "Contact information",
      editDetails: "Edit details",
      paymentMethod: "Payment method",
      editPaymentDetails: "Edit method",
      agreeWithOur: "Agree with our",
      termsConditions: "terms and conditions",
      privacyStatement: "privacy statement",
      continueToAgreeFirst: "By continuing to pay you agree with our",
      continueToAgreeSecond: "and our",
      showCountMore: "Show |%COUNT%| more",
      ringsize: "Ringsize",
      ringsizeToBeDetermined: "Ringsize to be determined",
      orderNumber: "Order number",
    },
    giftcards: {
      redeemGiftCard: "Redeem giftcard",
      giftcardNumber: "Giftcard number",
      securityCode: "Security code",
      redeem: "Redeem",
      cancel: "cancel",
      giftcardAdded: "Giftcard added",
      noMoreGiftcardsCanBeAdded: "No more giftcards can be added",
      giftcardHasBeenAdded: "The giftcard has been added to your order.",
      giftcardHasBeenRemoved: "The giftcard has been removed from your order.",
      giftcardNoAmountLeft: "The giftcard has no amount left",
    },
    voucher: {
      redeemVoucher: "Redeem voucher",
      voucherCode: "Voucher code",
      redeem: "Redeem",
      cancel: "cancel",
      voucherAdded: "Voucher added",
      noMoreVouchersCanBeAdded: "No more vouchers can be added",
      voucherHasBeenAdded: "The voucher has been added to your order.",
      voucherHasBeenRemoved: "The voucher has been removed from your order.",
      voucherIsInvalid: "The voucher code is not valid",
    },
    payment: {
      processing: "Processing",
      pleaseWait: "Hold on for a moment please",
      paymentError: "Payment error",
      pleaseContactUs: "An error occured in processing your payment",
      contactUs: "Contact us",
    },
    thanks: {
      thanks: "Thanks! Your order has been placed.",
      processing:
        "We are processing your order. We keep you updated about your order on",
      returnHome: "Homepage",
    },
    paymentError: {
      title: "Payment error",
      text: "An error occured while processing your payment. Please try again or contact us.",
      button: "Back to checkout",
    },
  },
}
