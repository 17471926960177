import styled from "styled-components"

import {
  space,
  SpaceProps,
  width,
  WidthProps,
  height,
  HeightProps,
  color,
  ColorProps,
  fontFamily,
  FontFamilyProps,
  textAlign,
  TextAlignProps,
  lineHeight,
  LineHeightProps,
  fontWeight,
  FontWeightProps,
  fontStyle,
  FontStyleProps,
  fontSize,
  FontSizeProps,
  letterSpacing,
  LetterSpacingProps,
  maxWidth,
  MaxWidthProps,
  maxHeight,
  MaxHeightProps,
  minWidth,
  MinWidthProps,
  minHeight,
  MinHeightProps,
  size,
  SizeProps,
  verticalAlign,
  VerticalAlignProps,
  alignItems,
  AlignItemsProps,
  justifyContent,
  JustifyContentProps,
  flexWrap,
  FlexWrapProps,
  flexDirection,
  FlexDirectionProps,
  flex,
  FlexProps,
  alignContent,
  AlignContentProps,
  justifySelf,
  JustifySelfProps,
  alignSelf,
  AlignSelfProps,
  order,
  OrderProps,
  flexBasis,
  FlexBasisProps,
  borderRadius,
  BorderRadiusProps,
  borderColor,
  BorderColorProps,
  borders,
  BorderProps,
  borderTop,
  BorderTopProps,
  borderRight,
  BorderRightProps,
  borderBottom,
  BorderBottomProps,
  borderLeft,
  BorderLeftProps,
  opacity,
  OpacityProps,
  overflow,
  OverflowProps,
  position,
  PositionProps,
  zIndex,
  ZIndexProps,
  top,
  TopProps,
  right,
  RightProps,
  bottom,
  BottomProps,
  LeftProps,
  left,
  GridGapProps,
  GridColumnGapProps,
  GridRowGapProps,
  GridColumnProps,
  GridRowProps,
  GridAutoFlowProps,
  GridAutoColumnsProps,
  GridAutoRowsProps,
  GridTemplateColumnsProps,
  GridTemplateRowsProps,
  GridTemplateAreasProps,
  GridAreaProps,
  gridGap,
  gridColumnGap,
  gridRowGap,
  gridColumn,
  gridRow,
  gridAutoFlow,
  gridAutoColumns,
  gridAutoRows,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  gridArea,
  DisplayProps,
  display,
  background,
  BackgroundProps,
  BackgroundSizeProps,
} from "styled-system"

export interface BoxProps
  extends SpaceProps,
    WidthProps,
    HeightProps,
    ColorProps,
    FontFamilyProps,
    TextAlignProps,
    LineHeightProps,
    FontWeightProps,
    FontStyleProps,
    FontSizeProps,
    LetterSpacingProps,
    MaxWidthProps,
    MaxHeightProps,
    MinWidthProps,
    MinHeightProps,
    SizeProps,
    VerticalAlignProps,
    AlignItemsProps,
    JustifyContentProps,
    FlexWrapProps,
    FlexDirectionProps,
    FlexProps,
    AlignContentProps,
    JustifySelfProps,
    AlignSelfProps,
    OrderProps,
    FlexBasisProps,
    BorderRadiusProps,
    BorderColorProps,
    BorderProps,
    BorderTopProps,
    BorderRightProps,
    BorderBottomProps,
    BorderLeftProps,
    OpacityProps,
    OverflowProps,
    PositionProps,
    ZIndexProps,
    TopProps,
    RightProps,
    BottomProps,
    LeftProps,
    GridGapProps,
    GridColumnGapProps,
    GridRowGapProps,
    GridColumnProps,
    GridRowProps,
    GridAutoFlowProps,
    GridAutoColumnsProps,
    GridAutoRowsProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps,
    GridTemplateAreasProps,
    GridAreaProps,
    DisplayProps,
    BackgroundSizeProps,
    BackgroundProps {}

export const Box = styled.div<BoxProps>`
  ${space};
  ${width};
  ${height};
  ${color};
  ${fontFamily};
  ${textAlign};
  ${lineHeight};
  ${fontWeight};
  ${fontStyle};
  ${fontSize};
  ${letterSpacing};
  ${maxWidth};
  ${maxHeight};
  ${minWidth};
  ${minHeight};
  ${size};
  ${verticalAlign};
  ${alignItems};
  ${justifyContent};
  ${flexWrap};
  ${flexDirection};
  ${flex};
  ${alignContent};
  ${justifySelf};
  ${alignSelf};
  ${order};
  ${flexBasis};
  ${borderRadius};
  ${borderColor};
  ${borders};
  ${borderTop};
  ${borderRight};
  ${borderBottom};
  ${borderLeft};
  ${opacity};
  ${overflow};
  ${position};
  ${zIndex};
  ${top};
  ${right};
  ${bottom};
  ${left};
  ${gridGap};
  ${gridColumnGap};
  ${gridRowGap};
  ${gridColumn};
  ${gridRow};
  ${gridAutoFlow};
  ${gridAutoColumns};
  ${gridAutoRows};
  ${gridTemplateColumns};
  ${gridTemplateRows};
  ${gridTemplateAreas};
  ${gridArea};
  ${display};
  ${background};
`
