export type CartContent = {
  total: string
  subTotal: string
  voucher: string
  shipping: string
  free: string
  secureCheckout: string
  checkout: string
  proceedToCheckout: string
  freeShipping: string
  freeReturns: string
  patentedSystem: string
  shoppingBag: string
  shoppingBagEmpty: string
  shoppingBagLoading: string
  anyQuestions: string
  phoneNumber: string
  products: string
  product: string
  ringSize: string
  quantity: string
  price: string
  configuration: string
  addStones: string
  addItems: string
  addAnItem: {
    stone: string
    base: string
    frame: string
  }
  edit: string
  remove: string
  addedToCart: string
  removedFromCart: string
  freeShippingReturns: string
  areYouSure: string
  closeSidebar: string
  closeSidebarAndTryAgain: string
  discoverCollection: string
  pickSize: string
  iDontKnow: string
  contactRingsize: string
}

type Cart = {
  [locale: string]: CartContent
}

export const cart: Cart = {
  nl: {
    total: "Totaal",
    subTotal: "Subtotaal",
    voucher: "Voucher",
    shipping: "Verzending",
    free: "Gratis in NL",
    secureCheckout: "Secure checkout",
    checkout: "Checkout",
    proceedToCheckout: "Doorgaan naar afrekenen",
    freeShipping: "Gratis verzending met PostNL in Nederland",
    freeReturns: "Free returns",
    patentedSystem: "Patented bayonet locking-system",
    shoppingBag: "Winkelmand",
    shoppingBagEmpty: "Uw winkelmand is leeg.",
    shoppingBagLoading: "Uw winkelmand wordt geladen...",
    anyQuestions: "Vragen? Bel: ",
    phoneNumber: "020 622 53 33",
    products: "producten",
    product: "product",
    ringSize: "ringmaat",
    quantity: "aantal",
    price: "prijs",
    configuration: "Configuratie",
    addStones: "Voeg stenen, frames of bases toe aan deze configuratie",
    addItems: "Voeg extra items toe",
    addAnItem: {
      stone: "Voeg een steen toe",
      base: "Voeg een base toe",
      frame: "Voeg een frame toe",
    },
    edit: "aanpassen",
    remove: "verwijderen",
    addedToCart: "is toegevoegd",
    removedFromCart: "is verwijderd",
    freeShippingReturns: "Free shipping and returns",
    areYouSure: "Weet je het zeker?",
    closeSidebar: "Sluit sidebar",
    closeSidebarAndTryAgain: "Sluit de sidebar en probeer het opnieuw",
    discoverCollection: "Bekijk onze collectie",
    pickSize: "Kies een maat",
    iDontKnow: "Weet ik niet",
    contactRingsize: "<b>Na aankoop</b> nemen wij contact op over uw ringmaat",
  },
  en: {
    total: "Total",
    subTotal: "Subtotal",
    voucher: "Voucher",
    shipping: "Shipping",
    free: "Free in NL",
    secureCheckout: "Secure checkout",
    checkout: "Checkout",
    proceedToCheckout: "Proceed to checkout",
    freeShipping: "Free shipping with PostNL in the Netherlands",
    freeReturns: "Free returns",
    patentedSystem: "Patented bayonet locking-system",
    shoppingBag: "Shopping bag",
    shoppingBagEmpty: "Your shopping bag is empty.",
    shoppingBagLoading: "Your shopping bag is being loaded...",
    anyQuestions: "any questions? call:",
    phoneNumber: "+31 (0) 020 622 53 33",
    products: "products",
    product: "product",
    ringSize: "ringsize",
    quantity: "quantity",
    price: "prijs",
    configuration: "Configuration",
    addStones: "Add stones, frames or bases to this configuration",
    addItems: "Add extra items",
    addAnItem: {
      stone: "Add a stone",
      base: "Add a base",
      frame: "Add a frame",
    },
    edit: "edit",
    remove: "remove",
    addedToCart: "added to cart",
    removedFromCart: "Removed from your cart",
    freeShippingReturns: "Free shipping and returns",
    areYouSure: "Are you sure?",
    closeSidebar: "Close sidebar",
    closeSidebarAndTryAgain: "Close sidebar and try again",
    discoverCollection: "Discover our collection",
    pickSize: "Pick a size",
    iDontKnow: "I don't know",
    contactRingsize:
      "We’ll contact you to find your ringsize <b>after purchase</b>",
  },
}
