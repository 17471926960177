export type VoteContent = {
  yourVotes: string
  voteNow: string
  next: string
  previous: string
  confirm: string
  thanks: string
  sendVotes: string
  consentWinners: string
  consentNewsletter: string
  votesSent: string
  willInform: string
  votingNotPossible: string
  viewResultsAfterElection: string
  viewResults: string
  pageTitle: string
  error: string
  winners: string
  organisedBy: string
  moreInfo: string
  photosTitle: string
}

type Vote = {
  [locale: string]: VoteContent
}

export const vote: Vote = {
  nl: {
    yourVotes: "Uw stemmen",
    voteNow: "Stem nu",
    next: "Volgende",
    previous: "Vorige",
    confirm: "Bevestig",
    thanks: "Bedankt",
    sendVotes: "Verstuur je stemmen",
    consentWinners:
      "Ik wil graag op de hoogte gehouden van de uitslag van deze verkiezing",
    consentNewsletter:
      "Ik wil op de hoogte gehouden van nieuws en aanbiedingen van Choices by DL en Gassan",
    votesSent: "Bedankt, je stemmen zijn verstuurd.",
    willInform: "We houden je op de hoogte op",
    votingNotPossible: "Stemmen is momenteel niet mogelijk",
    viewResultsAfterElection:
      "Wanneer de uitslag bekend is kun je de uitslag bekijken op de volgende pagina",
    viewResults: "Bekijk uitslag",
    pageTitle: "Verkiezing vrouwelijke entrepeneur 2022",
    error:
      "Er ging iets mis bij het versturen van je stemmen, mogelijk heb je al gestemd. Als dit niet zo is, neem dan alsjeblieft contact op met ons.",
    winners: "Winnaars",
    organisedBy: "Georganiseerd door",
    moreInfo: "Meer info",
    photosTitle: "A day with the nominees",
  },
  en: {
    yourVotes: "Your votes",
    voteNow: "Vote now",
    next: "Next",
    previous: "Previous",
    confirm: "Confirm",
    thanks: "Thank you",
    sendVotes: "Send your votes",
    consentWinners: "I'd like to be informed of the results of this election",
    consentNewsletter:
      "I'd like to be informed of news and offers from Choices by DL and Gassan",
    votesSent: "Thank you, your votes have been sent.",
    willInform: "We will inform you on",
    votingNotPossible: "Voting is not possible at this time",
    viewResultsAfterElection:
      "When the results are known you can view them on the following page",
    viewResults: "View results",
    pageTitle: "Election female entrepreneur 2022",
    error:
      "Something went wrong while submitting your votes, you may have already voted. If not, please contact us.",
    winners: "Winners",
    organisedBy: "Organised by",
    moreInfo: "More info",
    photosTitle: "A day with the nominees",
  },
}
