export type RetailersContent = {
  openingHours: string
  visitSite: string
  monday: string
  tuesday: string
  wednseday: string
  thursday: string
  friday: string
  saturday: string
  sunday: string
}

type Retailers = {
  [locale: string]: RetailersContent
}

export const retailers: Retailers = {
  nl: {
    openingHours: "Openingstijden",
    visitSite: "Bekijk website",
    monday: "Maandag",
    tuesday: "Dinsdag",
    wednseday: "Woensdag",
    thursday: "Donderdag",
    friday: "Vrijdag",
    saturday: "Zaterdag",
    sunday: "Zondag",
  },
  en: {
    openingHours: "Opening hours",
    visitSite: "Visit website",
    monday: "Monday",
    tuesday: "Tuesday",
    wednseday: "Wednseday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
}
