import styled, { css } from "styled-components"
import * as p from "polished"
import { ColorProps, color, SpaceProps, space } from "styled-system"

export const TitleSStyle = css<ColorProps & SpaceProps>`
  font-size: ${p.rem(20)};
  font-weight: normal;
  line-height: 1.3;
  font-family: ${(p) => p.theme.fonts.serif};
  ${color};
  ${space};
`

export const TitleS = styled.h4`
  ${TitleSStyle};
`
