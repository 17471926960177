export type CookiesContent = {
  noticeMsg: string
  noticeMsgSettings: string
  acceptAll: string
  popupTitle: string
  popupDescription: string
  popupDescriptionLink: string
  personalCookies: string
  personalDesc: string
  analyticalCookies: string
  analyticalDesc: string
  functionalCookies: string
  functionalDesc: string
  savePreferences: string
}

type Cookies = {
  [locale: string]: CookiesContent
}

export const cookies: Cookies = {
  nl: {
    noticeMsg:
      "Choices by DL gebruikt standaard cookies zodat de website goed functioneert. Door middel van persoonlijke cookies zorgen we er onder andere voor dat alleen relevante advertenties u bereiken. Aan de hand van analytische cookies krijgen we meer inzicht in hoe u onze website gebruikt en hoe effectief onze marketing is.",
    noticeMsgSettings: "Hier kunt u meer lezen en uw voorkeuren opgeven.",
    acceptAll: "Alles accepteren",
    popupTitle: "Cookie instellingen",
    popupDescription:
      "We gebruiken cookies voor verschillende doeleinden op onze website. Lees meer in ons",
    popupDescriptionLink: "privacy policy",
    personalCookies: "Persoonlijke cookies",
    personalDesc:
      "Persoonlijke cookies zorgen er onder andere voor dat alleen relevante advertenties u bereiken",
    analyticalCookies: "Analytische cookies",
    analyticalDesc:
      "Aan de hand van analytische cookies krijgen we meer inzicht in hoe u onze website gebruikt en hoe effectief onze marketing is.",
    functionalCookies: "Functionele cookies",
    functionalDesc:
      "Standaard cookies zorgen ervoor dat we website/webshop goed functioneert.",
    savePreferences: "Voorkeuren opslaan",
  },
  en: {
    noticeMsg:
      "Choices by DL uses standard functional cookies to make sure the website works as expected. Using personal cookies we ensure only relevant ads reach you. Through analytical cookies we gain insight into how you use our website and how efficient our marketing is. ",
    noticeMsgSettings: "Here you can read more and specify your preferences.",
    acceptAll: "Accept all",
    popupTitle: "Cookie settings",
    popupDescription:
      "We use cookies on this website a for several purposes. Read more about them in our",
    popupDescriptionLink: "privacy policy",
    personalCookies: "Personal cookies",
    personalDesc:
      "These cookies make sure information is shown to you based on personal preferences and ensures only relevant ads reach you.",
    analyticalCookies: "Analytical cookies",
    analyticalDesc:
      "These cookies help us to understand how you visit our websites and how effective our marketing is.",
    functionalCookies: "Functional cookies",
    functionalDesc:
      "These cookies are required to show you a working website and basic analytics.",
    savePreferences: "Save preferences",
  },
}
