export type LookDetailContent = {
  nextLook: string
  jewelryInThisLook: string
  otherLooks: string
  viewAll: string
}

type LookDetail = {
  [locale: string]: LookDetailContent
}

export const lookDetail: LookDetail = {
  nl: {
    nextLook: "Volgende look",
    jewelryInThisLook: "Sieraden in deze look",
    otherLooks: "Andere looks",
    viewAll: "Bekijk alles",
  },
  en: {
    nextLook: "Next look",
    jewelryInThisLook: "Jewerly in this look",
    otherLooks: "Andere looks",
    viewAll: "View all",
  },
}
