import styled, { css } from "styled-components"
import { breakpoints } from "@config/theme"
import * as p from "polished"
import { SpaceProps, space } from "styled-system"

export const TitleLStyle = css`
  font-size: ${p.rem(32)};
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fonts.serif};

  @media screen and (max-width: ${breakpoints.small}) {
    font-size: ${p.rem(20)};
    line-height: 1.2;
    line-height: "inherit";
  }
`

export const TitleL = styled.h2<SpaceProps>`
  ${space};
  ${TitleLStyle};
`

TitleL.defaultProps = {
  mb: 3,
}
