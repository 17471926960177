export type ReturnFormContent = {
  isRequired: string
  name: string
  email: string
  orderNumber: string
  productIds: string
  productIdsSub: string
  productIdsError: string
  reason: string
  comment: string
  emailError: string
  submit: string
  submitForm: string
  select: string
  changeFor: string
  submissionReturnSuccess: string
  submissionReturnFailed: string
  reasons: string[]
}

type ReturnForm = {
  [locale: string]: ReturnFormContent
}

export const returnForm: ReturnForm = {
  nl: {
    isRequired: "is een verplicht veld",
    name: "Naam",
    email: "Email",
    orderNumber: "Ordernummer",
    productIds: "Artikelnummers",
    productIdsSub: "Komma gescheiden",
    productIdsError:
      "Artikelnummers moeten komma gescheiden worden ingevoerd: 1234,5678",
    reason: "Reden",
    comment: "Opmerking",
    emailError: "Voer een geldig emailadres in",
    submit: "Verstuur",
    submitForm: "Verstuur formulier",
    select: "Selecteer",
    changeFor: "Ruilen voor",
    submissionReturnSuccess:
      "Uw retourmelding is gelukt, we nemen zo snel mogelijk contact met u op",
    submissionReturnFailed:
      "Er ging iets fout bij het verzenden van het bericht, probeer het nog eens",
    reasons: [
      "Past niet goed",
      "Meer dan 1 maat besteld",
      "Kleur / design voldoet niet aan mijn verwachtingen",
      "Kwaliteit niet naar verwachting",
      "Te late levering",
      "Verkeerd product",
      "Artikel is beschadigd",
      "Ruilen",
    ],
  },
  en: {
    isRequired: "is a required field",
    name: "Name",
    email: "Email",
    orderNumber: "Order number",
    productIds: "Article numbers",
    productIdsSub: "Comma separated",
    productIdsError: "Please enter article numbers comma separated: 1234,5678",
    reason: "Reason",
    comment: "Comment",
    emailError: "Please enter a valid email",
    submit: "Submit",
    select: "Select",
    changeFor: "Change for",
    submitForm: "submitForm form",
    submissionReturnSuccess:
      "Your return request has been received, we will contact you as soon as possible",
    submissionReturnFailed: "Something went wrong, please try again",
    reasons: [
      `Didn't fit well`,
      "Ordered more then one size",
      `Color / design doesn't fit my expectations`,
      `Quality doesn't match expectatons`,
      `Delivery was too late`,
      "Wrong product",
      "Article is damaged",
      "Change",
    ],
  },
}
