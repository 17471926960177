import styled, { css } from "styled-components"
import { breakpoints } from "@config/theme"
import * as p from "polished"

export const TitleXXLStyle = css`
  font-weight: normal;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fonts.serif};
  font-size: ${p.rem(23)};
  line-height: 1.4;

  @media screen and (min-width: ${breakpoints.small}) {
    font-size: ${p.rem(40)};
    line-height: 1.5;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    font-size: ${p.rem(48)};
  }
`

export const TitleXXL = styled.h1`
  ${TitleXXLStyle};
`
