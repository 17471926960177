import styled, { css } from "styled-components"
import { breakpoints } from "@config/theme"
import * as p from "polished"
import { ColorProps, color } from "styled-system"

export const LabelStyle = css<ColorProps>`
  font-size: ${p.rem(14)};
  font-weight: normal;
  line-height: ${p.rem(19)};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  ${color};
  @media screen and (max-width: ${breakpoints.small}) {
    font-size: ${p.rem(12)};
  }
`

export const Label = styled.p`
  ${LabelStyle};
`
