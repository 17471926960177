import styled, { css } from "styled-components"
import * as p from "polished"
import { ColorProps, color } from "styled-system"

export const PriceStyle = css`
  font-size: ${p.rem(14)};
  line-height: ${p.rem(22)};
  letter-spacing: 0.06rem;
  text-transform: uppercase;
`

export const Price = styled.p<ColorProps>`
  ${PriceStyle};
  ${color};
`
