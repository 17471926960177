import styled, { css } from "styled-components"
import * as p from "polished"

export const TitleXSStyle = css`
  font-size: ${p.rem(16)};
  font-weight: normal;
  line-height: ${p.rem(21)};
  font-family: ${(p) => p.theme.fonts.serif};
`

export const TitleXS = styled.h5`
  ${TitleXSStyle};
`
