import styled, { css } from "styled-components"
import * as p from "polished"
import { space, SpaceProps } from "styled-system"

export const ParagraphTitleStyle = css`
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  line-height: ${p.rem(22)};
  text-transform: uppercase;
`

export const ParagraphTitle = styled.h4<SpaceProps>`
  ${ParagraphTitleStyle};
  ${space};
`

ParagraphTitle.defaultProps = {
  mb: "1rem",
}
