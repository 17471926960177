export type WarrantyContent = {
  isRequired: string
  firstName: string
  lastName: string
  email: string
  emailError: string
  purchaseDate: string
  purchaseDateError: string
  newsletterSignup: string
  submit: string
  submissionSuccess: string
  submissionFailed: string
}

type Warranty = {
  [locale: string]: WarrantyContent
}

export const warranty: Warranty = {
  nl: {
    isRequired: "is een verplicht veld",
    firstName: "Voornaam",
    lastName: "Achternaam",
    email: "Email",
    purchaseDate: "Aankoopdatum",
    purchaseDateError: "Voor een geldige aankoopdatum in",
    emailError: "Voer een geldig emailadres in",
    newsletterSignup: "Inschrijven voor de nieuwsbrief",
    submit: "Verstuur",
    submissionSuccess:
      "Uw garantie aanmelding is gelukt, u ontvangt uw garantie code per email.",
    submissionFailed:
      "Er ging iets fout bij het verzenden van het bericht, probeer het nog eens",
  },
  en: {
    isRequired: "is a required field",
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    emailError: "Please enter a valid email",
    purchaseDate: "Purchase date",
    purchaseDateError: "Please enter a valid purchase date",
    submit: "Submit",
    newsletterSignup: "Signup for our newsletter",
    submissionSuccess: `Your warranty request has been received, you'll receive the warranty code via email`,
    submissionFailed: "Something went wrong, please try again",
  },
}
