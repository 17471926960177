import styled, { css } from "styled-components"
import * as p from "polished"
import {
  SpaceProps,
  space,
  ColorProps,
  color,
  DisplayProps,
  display,
  FontSizeProps,
  fontSize,
  LineHeightProps,
  lineHeight,
} from "styled-system"

interface Props
  extends SpaceProps,
    ColorProps,
    DisplayProps,
    FontSizeProps,
    LineHeightProps {}

export const ParagraphStyle = css`
  font-size: ${p.rem(16)};
  white-space: pre-line;
  font-weight: normal;
  line-height: ${p.rem(24)};
`

export const Paragraph = styled.p<Props>`
  ${ParagraphStyle};
  ${space};
  ${color};
  ${display};
  ${fontSize};
  ${lineHeight};
`

Paragraph.defaultProps = {
  marginBottom: "1rem",
}
