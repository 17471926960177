export type NewsletterContent = {
  isRequired: string
  firstName: string
  lastName: string
  email: string
  emailError: string
  submit: string
  submissionSuccess: string
  submissionFailed: string
}

type Newsletter = {
  [locale: string]: NewsletterContent
}

export const newsletter: Newsletter = {
  nl: {
    isRequired: "is een verplicht veld",
    firstName: "Voornaam",
    lastName: "Achternaam",
    email: "Email",
    emailError: "Voer een geldig emailadres in",
    submit: "Verstuur",
    submissionSuccess: "U bent succesvol ingeschreven voor onze nieuwsbrief",
    submissionFailed:
      "Er ging iets fout bij het verzenden van het bericht, probeer het nog eens",
  },
  en: {
    isRequired: "is a required field",
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    emailError: "Please enter a valid email",
    submit: "Submit",
    submissionSuccess: `You have successfully signed up for our newsletter`,
    submissionFailed: "Something went wrong, please try again",
  },
}
