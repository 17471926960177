export type PropertiesContent = {
  style: {
    title: string
    options: { [key: string]: string }
  }
  size: {
    title: string
    options: { [key: string]: string }
  }
  material: {
    title: string
    options: { [key: string]: string }
  }
  cut: {
    title: string
    options: { [key: string]: string }
  }
}

type Properties = {
  [locale: string]: PropertiesContent
}

export const properties: Properties = {
  nl: {
    style: {
      title: "Collectie",
      options: {
        hippiechic: "Hippie Chic",
        classic: "Classic",
        oval: "Oval",
        twin: "Twin",
        onepiece: "One Piece",
      },
    },
    size: {
      title: "Grootte",
      options: {
        large: "16x16mm",
        medium: "12x12mm",
        small: "8x8mm",
      },
    },
    material: {
      title: "Materiaal",
      options: {
        ceramic: "Ceramic",
        diamonds: "Diamonds",
        rosegold: "Rosé Gold",
        whitegold: "White Gold",
        yellowgold: "Yellow Gold",
      },
    },
    cut: {
      title: "Cut",
      options: {
        cabochon: "Cabochon",
        faceted: "Faceted",
      },
    },
  },
  en: {
    style: {
      title: "Collection",
      options: {
        hippiechic: "Hippie Chic",
        classic: "Classic",
        oval: "Oval",
        twin: "Twin",
        onepiece: "One Piece",
      },
    },
    size: {
      title: "Size",
      options: {
        large: "16x16mm",
        medium: "12x12mm",
        small: "8x8mm",
      },
    },
    material: {
      title: "Material",
      options: {
        ceramic: "Ceramic",
        diamonds: "Diamonds",
        rosegold: "Rosé Gold",
        whitegold: "White Gold",
        yellowgold: "Yellow Gold",
      },
    },
    cut: {
      title: "Cut",
      options: {
        cabochon: "Cabochon",
        faceted: "Faceted",
      },
    },
  },
}
